import { useFetch, useRuntimeConfig } from '#imports';
import { $fetch as ofetch } from 'ofetch'; // eslint-disable-line import/no-extraneous-dependencies

/**
 * Set base url and include credentials by default for fetch
 *
 * @param {String} url
 * @param {Object} options
 *
 * @returns {String}
 */
export default async function useStatefulFetch(url, options = {}) {
  return useFetch(url, {
    baseURL: useRuntimeConfig().public.API_HOST,
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    server: false,
    ...options,
  });
}

/**
 * Set base url and include credentials by default for $fetch
 *
 * @param {String} url
 * @param {Object} options
 *
 * @returns {String}
 */
export async function $fetch(url, options = {}) {
  return ofetch(url, {
    baseURL: useRuntimeConfig().public.API_HOST,
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    server: false,
    ...options,
  });
}
